import { useKeycloakTokenPermissions } from '@components/AppInfoContext/utils/tokenPermissions';
import { useAppInfo } from '@components/AppInfoContext/use-app-info';
import { ContentErrorHandler } from '@components/ContentErrorHandler/ContentErrorHandler';
import { ErrorBoundary } from '@components/ErrorBoundary/ErrorBoundary';
import { FeaturesTogglesChecker } from '@components/FeaturesToggles/components/FeaturesTogglesChecker';
import { Header } from '@components/Header/Header';
import { MissingPermissionsMessage } from '@components/MissingPermissionsMessage/MissingPermissionsMessage';
import { PermissionChecker } from '@components/Permission/components/PermissionChecker';
import { navigationWidth, SectionContainer } from '@components/SectionContainer/SectionContainer';
import { SideNavigation } from '@components/SideNavigation/SideNavigation';
import { tokenPermissions } from '@state/token-permission.state';
import { AssetMetaModelPermissions, AssetPermissions, UserPermissions } from '@teci/ecockpit-frontend-gateway-api';
import { Main } from '@utils/styles/structure.styles';
import i18next from 'i18next';
import { useAtom, useUpdateAtom } from '@utils/state.utils';
import React, { lazy, Suspense, useEffect, useLayoutEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { presentation } from '@state/presentation.state';
import { ServiceUnavailableMessage } from '@components/ServiceUnavailableMessage/ServiceUnavailableMessage';
import { Context } from '@apollo/client';
import { RelationTypes } from '@components/RelationTypes/relationTypes.types';
import { useAppDispatch } from './store/hooks';
import { fetchFeatureConfig } from './store/reduxGlobal/thunks/fetchFeatureConfig';
import { fetchRelationTypes } from './store/reduxGlobal/thunks/fetchRelationTypes';
import { RelationTypesProvider } from '@components/RelationTypes/RelationTypes.provider';

const AssetCreate = lazy(() => import('@screens/AssetDetails/AssetCreate/AssetCreate'));
const AssetEdit = lazy(() => import('@screens/AssetDetails/AssetEdit/AssetEdit'));
const AssetOverview = lazy(() => import('@screens/AssetOverview/AssetOverview'));
const Eka = lazy(() => import('@screens/Eka/Eka.screen'));
const DeviceStatusOverview = lazy(() => import('@screens/DeviceStatusOverview/DeviceStatusOverview'));
const UserOverview = lazy(() => import('@screens/UserOverview/UserOverview'));

const AssetTypeScreen = lazy(() => import('@screens/AssetType/AssetType.screen'));

export const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const permissions = useKeycloakTokenPermissions();
  const setTokenPermissions = useUpdateAtom(tokenPermissions);
  const { language, tenantMeta, accessToken, context } = useAppInfo();
  const favIconUrl = tenantMeta?.theme.faviconUri;
  const [{ showHeader, isNarrowContainer }] = useAtom(presentation);

  useEffect(() => {
    (window as any).appContext = {
      bus: { url: `${context.services.cloud}/kiwibus` },
      oauth: {
        accessToken: accessToken,
      },
      oauthServer: context.keycloak.url,
      resourceServer: context.services.cloud,
    };
    if (document.getElementById('usercentrics-cmp') === null) {
      const script = document.createElement('script');
      script.async = true;
      script.id = 'usercentrics-cmp';
      script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
      script.setAttribute('data-settings-id', tenantMeta?.defaults.centricsId ?? '');
      document.head.appendChild(script);
    }
  });

  // retrieve permissions and set to global state
  useEffect(() => {
    setTokenPermissions(permissions);
  }, [permissions, setTokenPermissions]);

  useEffect(() => {
    const lng = language ?? 'de';
    i18next.changeLanguage(lng);
    document.documentElement.setAttribute('lang', lng);
  }, [language]);

  useEffect(() => {
    dispatch(fetchRelationTypes());
    dispatch(fetchFeatureConfig('EKA'));
  }, [dispatch]);

  useLayoutEffect(() => {
    if (!favIconUrl) return;

    document.head.querySelector('link[rel="icon"]')?.setAttribute('href', favIconUrl);
  }, [favIconUrl]);
  return (
    <RelationTypesProvider
      children={(relationTypes: RelationTypes) => (
        <>
          <SideNavigation width={navigationWidth} />

          <SectionContainer>
            {showHeader ? <Header /> : null}

            <Main narrow={isNarrowContainer}>
              <Suspense fallback={null}>
                <ErrorBoundary FallbackComponent={ContentErrorHandler}>
                  <Routes>
                    <Route path="/" element={redirectAccordingToRealm(context)} />

                    <Route
                      path="/assets"
                      element={
                        <PermissionChecker expectedPermissions={[AssetPermissions.ECOCKPIT_ASSET_READ]}>
                          {(hasReadAccess) =>
                            showScreenAccordingToPermissionsAndRelationTypes(
                              hasReadAccess,
                              relationTypes,
                              'asset-overview'
                            )
                          }
                        </PermissionChecker>
                      }
                    />

                    <Route
                      path="/assets/new/:selectedAssetTypeId/:parentAssetId?"
                      element={
                        <PermissionChecker expectedPermissions={[AssetPermissions.ECOCKPIT_ASSET_CREATE]}>
                          {(hasCreateAccess) =>
                            showScreenAccordingToPermissionsAndRelationTypes(
                              hasCreateAccess,
                              relationTypes,
                              'asset-create'
                            )
                          }
                        </PermissionChecker>
                      }
                    />

                    <Route
                      path="/assets/detail/:assetId"
                      element={
                        <PermissionChecker expectedPermissions={[AssetPermissions.ECOCKPIT_ASSET_READ]}>
                          {(hasReadAccess) =>
                            showScreenAccordingToPermissionsAndRelationTypes(
                              hasReadAccess,
                              relationTypes,
                              'asset-update'
                            )
                          }
                        </PermissionChecker>
                      }
                    />

                    <Route
                      path="/asset-types/*"
                      element={
                        <PermissionChecker expectedPermissions={[AssetMetaModelPermissions.ECOCKPIT_ASSET_TYPE_READ]}>
                          {(hasReadAccess) =>
                            showScreenAccordingToPermissionsAndRelationTypes(
                              hasReadAccess,
                              relationTypes,
                              'asset-types'
                            )
                          }
                        </PermissionChecker>
                      }
                    />

                    <Route
                      path="/device-errors"
                      element={
                        <PermissionChecker
                          expectedPermissions={[
                            AssetPermissions.ECOCKPIT_ASSET_READ,
                            AssetPermissions.ECOCKPIT_ASSET_READ_CHANNEL,
                          ]}
                        >
                          {(hasReadAndReadChannelAccess) => {
                            return hasReadAndReadChannelAccess ? (
                              <DeviceStatusOverview />
                            ) : (
                              <MissingPermissionsMessage />
                            );
                          }}
                        </PermissionChecker>
                      }
                    />

                    <Route
                      path="/users"
                      element={
                        <PermissionChecker expectedPermissions={[UserPermissions.ECOCKPIT_USER_VIEW_USER_INFORMATION]}>
                          {(hasAccess: boolean) => (hasAccess ? <UserOverview /> : <MissingPermissionsMessage />)}
                        </PermissionChecker>
                      }
                    />

                    <Route
                      path="/eka/*"
                      element={
                        <FeaturesTogglesChecker featureToggle="EKA">
                          {(isActive: boolean) =>
                            isActive ? showEkaScreenAccordingToRelationTypes(relationTypes) : null
                          }
                        </FeaturesTogglesChecker>
                      }
                    />
                  </Routes>
                </ErrorBoundary>
              </Suspense>
            </Main>
          </SectionContainer>
        </>
      )}
    />
  );
};

function redirectAccordingToRealm(context: Context) {
  if (context.keycloak.realm === 'iwb') {
    return <Navigate replace to={'/eka'} />;
  } else {
    return <Navigate replace to={'/assets'} />;
  }
}

function showScreenAccordingToPermissionsAndRelationTypes(
  hasPermissions: boolean,
  relationTypes: RelationTypes | undefined,
  screen: 'dashboard' | 'asset-types' | 'asset-create' | 'asset-update' | 'asset-overview'
) {
  if (hasPermissions) {
    if (relationTypes) {
      switch (screen) {
        case 'asset-types':
          return <AssetTypeScreen relationTypes={relationTypes} />;
        case 'asset-create':
          return <AssetCreate relationTypeId={relationTypes[0]?.id} />;
        case 'asset-update':
          return <AssetEdit relationTypeId={relationTypes[0]?.id} />;
        case 'asset-overview':
          return <AssetOverview relationTypeId={relationTypes[0]?.id} />;
        default:
          return null;
      }
    } else {
      return <ServiceUnavailableMessage />;
    }
  } else {
    return <MissingPermissionsMessage />;
  }
}

function showEkaScreenAccordingToRelationTypes(relationTypes: RelationTypes | undefined) {
  if (relationTypes) {
    return <Eka />;
  } else {
    return <ServiceUnavailableMessage />;
  }
}
